import request from '@/request';

// 获取所有科目
export function GetSubjectList (data) {
    return request({
        url: '/exam/subject/list',
        method: 'get',
    });
}

// pc-获取个人档案
export function UserModuleData (data) {
    return request({
        url: '/user/module/data?subjectId='+data.subjectId+'&startTime='+data.startTime+'&endTime='+data.endTime,
        method: 'get',
    });
}