<template>
    <div class="MyFiles">
        <div class="wrap">
            <Head />
            <div class="middle">
                <div class="content">
                    <!-- 选择科目 -->
                    <div class="Choose_subject">
                        <span>请选择你想查看的科目</span>
                        <Select v-model="subjecparams.subjectId" size="small" style="width: 151px;" @on-change="SelectSubject">
                            <Option v-for="item in SubjectList" :value="item.subjectId" :key="item.subjectId">{{item.title}}</Option>
                        </Select>
                    </div>
                    <!-- 整体刷题数据报告 -->
                    <div class="Brush_data_report">
                        <div class="report_top">
                            <div class="report_tit">整体刷题数据报告</div>
                            <DatePicker type="daterange" placement="bottom-end" split-panels placeholder="选择起止日期" style="width: 210px" size="small" @on-change="SelectDate"></DatePicker>
                        </div>
                        <div class="report_content" v-if="tableList.length != 0">
                            <div class="report_left">
                                <div class="moduleName_list">
                                    <div class="list_item" v-for="(item,index) in tableList" :key="item.moduleId" :class="{active:moduleId == item.moduleId}"
                                    @click="ClickModule(item)">
                                        {{item.moduleName}}
                                    </div>
                                </div>
                            </div>
                            <div class="report_right">
                                <div class="allList" v-if="submodulelist.length != 0">
                                    <div class="Tablelist_item">
                                        <Card :padding="0">
                                            <Table border stripe size="small" :columns="submodulecolumns" :data="submodulelist">
                                                <template slot-scope="{ row, index }" slot="rightRate">
                                                    <div>{{ row.rightRate }}%</div>
                                                </template>
                                                <template slot-scope="{ row, index }" slot="allRightRate">
                                                    <div>{{ row.allRightRate }}%</div>
                                                </template>
                                                <template slot-scope="{ row, index }" slot="avgUseTime">
                                                    <div>{{ formatSeconds(row.avgUseTime) }}</div>
                                                </template>
                                                <template slot-scope="{ row, index }" slot="allAvgUseTime">
                                                    <div>{{ formatSeconds(row.allAvgUseTime) }}</div>
                                                </template>
                                                <template slot-scope="{ row, index }" slot="operation" v-if="row.pointName != '合计'">
                                                    <a class="operation" v-if="submoduleindex != index" @click="SelectedBig(row.pointList,index)">放大查看</a>
                                                    <div v-if="submoduleindex == index">放大中</div>
                                                </template>
                                            </Table>
                                        </Card>
                                    </div>
                                    <div class="Tablelist_item">
                                        <Card :padding="0">
                                            <Table border stripe size="small" :columns="pointcolumns" :data="pointList">
                                                <template slot-scope="{ row, index }" slot="rightRate">
                                                    <div>{{ row.rightRate }}%</div>
                                                </template>
                                                <template slot-scope="{ row, index }" slot="allRightRate">
                                                    <div>{{ row.allRightRate }}%</div>
                                                </template>
                                                <template slot-scope="{ row, index }" slot="avgUseTime">
                                                    <div>{{ formatSeconds(row.avgUseTime) }}</div>
                                                </template>
                                                <template slot-scope="{ row, index }" slot="allAvgUseTime">
                                                    <div>{{ formatSeconds(row.allAvgUseTime) }}</div>
                                                </template>
                                            </Table>
                                        </Card>
                                    </div>
                                </div>
                                <div class="submodule_Ellipsis" v-if="submodulelist.length == 0">
                                    <img src="@/assets/report_Ellipsis.png" alt="">
                                    <p>您目前没有这个模块的相关数据</p>
                                    <p>请做题后再查看</p>
                                </div>
                            </div>
                        </div> 
                        <!-- 加载中 -->
                        <Spin fix v-if="file_Loading">
                            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>
                        <div class="report_Ellipsis" v-if="tableList.length == 0">
                            <img src="@/assets/report_Ellipsis.png" alt="">
                            <p>您目前没有这个模块的相关数据</p>
                            <p>请做题后再查看</p>
                        </div>
                    </div>
                    <!-- 统计数据 -->
                    <div class="statistics">
                        <div class="statistics_tit">
                            <span class="title">全盘统计情况</span>
                            <span v-show="tableList.length != 0">您一共参加过{{mockNum}}次模考，平均分为{{mockAvgScore}}分，平均交卷耗时为{{mockAvgTime}}</span>
                        </div>
                        <!-- 加载中 -->
                        <Spin fix v-if="file_Loading">
                            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>
                        <div class="allstatistics" v-show="tableList.length != 0">
                            <Card class="statistics_item">
                                <img src="@/assets/Statistical_label1.png" alt="">
                                <div id="questionsNum" style="width: 373px;height:300px;"></div>
                            </Card>
                            <Card class="statistics_item">
                                <img src="@/assets/Statistical_label2.png" alt="">
                                <div id="accuracy" style="width: 373px;height:300px;"></div>
                            </Card>
                            <Card class="statistics_item">
                                <img src="@/assets/Statistical_label3.png" alt="">
                                <div id="time_consuming" style="width: 373px;height:310px;"></div>
                            </Card>
                        </div>
                        <div class="statistics_Ellipsis" v-show="tableList.length == 0">
                            <img src="@/assets/statistics_Ellipsis.png" alt="">
                            <p>你还没做题哦，哪来的数据呢</p>
                        </div>
                    </div>

                    <!-- 模考记录分析 -->
                    <div class="mock_analyse">
                        <!-- 加载中 -->
                        <Spin fix v-if="mock_Loading">
                            <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                            <div>Loading</div>
                        </Spin>
                        <div class="mock_analyse_tit">模考记录分析</div>
                        <div class="mock_analyse_content">
                            <div class="allmockList" v-if="mockList != 0">
                                <Card :padding="0" class="mockList_item" v-for="(item,index) in mockList" :key="item.userPaperId">
                                    <div class="item_line"></div>
                                    <div class="item_name">{{item.name}}</div>
                                    <div class="finish_time">完成时间：{{fmtDate(item.postedAt)}}</div>
                                    <div class="item_Table">
                                        <Table border stripe size="small" :columns="mockcolumns" :data="item.tableList"></Table>
                                    </div>
                                    <div class="item_btn">
                                        <Button type="primary" @click="Checkpaper(item)">查看该答卷</Button>
                                    </div>
                                </Card>
                            </div>
                            <div class="analyse_Ellipsis" v-if="mockList == 0">
                                <img src="@/assets/Analyse_Ellipsis.png" alt="">
                                <p>只有做完摸考试卷才有相关的记录分析可查看哦</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="footer">
            <iCopyright />
        </footer>
        <!-- 右下角弹窗 -->
        <div class="fixed_btn">
            <div class="contactkf" @click="ClickKf">
                <img src="@/assets/contactkf.png" alt="">
            </div>
            <div class="diss" @click="ClickDiss">
                <img src="@/assets/diss.png" alt="">
            </div>
        </div>
        <!-- 联系客服弹窗 -->
        <Kfmodal ref="Click_kf"/>
        <!-- 我要吐槽弹窗 -->
        <Dissmodal ref="Click_Diss" :title="Diss_title"/>
    </div>
</template>

<script>
// 页头
import Head from "@/components/head"
// 页脚
import iCopyright from "@/components/footer"
// 联系客服弹窗
import Kfmodal from "@/components/kfmodal"
// 我要吐槽弹窗
import Dissmodal from "@/components/Dissmodal"
import { GetSubjectList, UserModuleData} from "@/api/MyFiles"
import { GetUserInfo } from "@/api/Home";
import * as echarts from 'echarts/core';
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components';
import { RadarChart, PieChart } from 'echarts/charts';
import { CanvasRenderer} from 'echarts/renderers';
echarts.use([TitleComponent, TooltipComponent, LegendComponent, RadarChart, PieChart, CanvasRenderer]);
export default {
    name: 'MyFiles',
    props: {},
    components: {
        Head,
        iCopyright,
        Kfmodal,
        Dissmodal,
    },
    data () {
        return {
            // 科目列表
            SubjectList: [],
            subjecparams:{
                subjectId: "",
                startTime: "",
                endTime: ""
            },
            // 模块列表
            tableList:[],
            // 当前选中的模块id
            moduleId: "",
            // 子模块列表
            submodulelist: [],
            submodulecolumns: [
                {
                    title: '模块',
                    key: 'pointName',
                    align: "center",
                },
                {
                    title: '做题量',
                    key: 'totalNum',
                    align: "center",
                },
                {
                    title: '正确率',
                    slot: 'rightRate',
                    align: "center",
                },
                {
                    title: '平均水平',
                    slot: 'allRightRate',
                    align: "center",
                },
                {
                    title: '每题耗时',
                    slot: 'avgUseTime',
                    align: "center",
                },
                {
                    title: '平均水平',
                    slot: 'allAvgUseTime',
                    align: "center",
                },
                {
                    title: '操作',
                    slot: 'operation',
                    align: "center",
                },
            ],
            // 子模块选中放大
            submoduleindex: 0,
            // 知识点列表
            pointList: [],
            pointcolumns: [
                {
                    title: '知识点',
                    key: 'pointName',
                    align: "center",
                },
                {
                    title: '做题量',
                    key: 'totalNum',
                    align: "center",
                },
                {
                    title: '正确率',
                    slot: 'rightRate',
                    align: "center",
                },
                {
                    title: '平均水平',
                    slot: 'allRightRate',
                    align: "center",
                },
                {
                    title: '每题耗时',
                    slot: 'avgUseTime',
                    align: "center",
                },
                {
                    title: '平均水平',
                    slot: 'allAvgUseTime',
                    align: "center",
                },
            ],
            // 模考次数
            mockNum: 0,
            // 平均得分
            mockAvgScore: 0,
            // 平均用时，秒
            mockAvgTime: 0,
            // 3个图标数据
            chartData: {},
            file_Loading: true,
            mock_Loading: true,
            // 模考分析列表
            mockList: [],
            mockcolumns: [
                {
                    title: '模块',
                    key: 'moduleName',
                    align: "center",
                },
                {
                    title: '做题量',
                    key: 'totalNum',
                    align: "center",
                    width: 57
                },
                {
                    title: '正确率',
                    key: 'rightRate',
                    align: "center",
                    width: 57
                },
                {
                    title: '平均每题耗时',
                    key: 'useTimeAvg',
                    align: "center",
                },
            ],
            Diss_title: "档案页面"
        }
    },
    created () {
        this.GetSubjectData()
    },
    mounted () {
        
    },
    methods: {
        // 获取所有科目
        GetSubjectData(){
            GetSubjectList().then((res) => {
                if(res.ret == 1){
                    this.SubjectList = res.data
                    this.subjecparams.subjectId = res.data[0].subjectId
                    this.GetUserModuleData()
                    this.GetUserInfo()
                }else{
                    console.log(res.msg)
                }
            })
        },

        // 选择科目
        SelectSubject(e){
            this.subjecparams.subjectId = e
            this.GetUserModuleData()
            this.GetUserInfo()
        },

        // 选择日期
        SelectDate(e){
            this.subjecparams.startTime = e[0]
            this.subjecparams.endTime = e[1]
            this.GetUserModuleData()
        },

        // pc-获取个人档案
        GetUserModuleData(){
            this.file_Loading = true
            this.submoduleindex = 0
            UserModuleData(this.subjecparams).then((res) => {
                if(res.ret == 1){
                    this.tableList = res.data.tableList
                    // console.log(this.tableList)
                    this.moduleId = this.tableList[0].moduleId
                    if(this.tableList.length !=0){
                        this.tableList.map((item,index) =>{
                            // 合计
                            let allList = {}
                            allList.pointName = "合计"
                            allList.totalNum = item.totalNum
                            allList.rightRate = item.rightRate
                            allList.allRightRate = item.allRightRate
                            allList.avgUseTime = item.avgUseTime
                            allList.allAvgUseTime = item.allAvgUseTime
                            item.pointList.push(allList)
                        })
                        // 子模块列表
                        this.submodulelist = this.tableList[0].pointList
                        if(this.submodulelist.length !=0){
                            // 知识点列表
                            this.pointList = this.tableList[0].pointList[0].pointList
                        }
                    }
                    this.mockNum = res.data.mockNum
                    this.mockAvgScore = res.data.mockAvgScore
                    this.mockAvgTime = this.formatSeconds(res.data.mockAvgTime)
                    // 统计图数据
                    this.chartData = res.data.chartData
                    // 做题量最大值
                    let seriesNumDataMAX = Math.max(...this.chartData.userNumChartData.seriesNumData)
                    // 做题量
                    this.chartData.userNumChartData.xAxis = this.setName(this.chartData.userNumChartData.xAxis,seriesNumDataMAX)
                    this.myquestionsNum(this.chartData.userNumChartData.xAxis,this.chartData.userNumChartData.seriesNumData)
                    // 正确率
                    this.chartData.userRateChartData.xAxis = this.setName(this.chartData.userRateChartData.xAxis)
                    this.myaccuracy(this.chartData.userRateChartData.xAxis,this.chartData.userRateChartData.seriesRateData)
                    // 耗时统计图数据
                    let result = this.chartData.userTimeChartData.xAxis.map((name,i) => ({name, value: this.chartData.userTimeChartData.seriesTimeData[i]}));
                    this.mytime_consuming(result)
                }else{
                    console.log(res.msg)
                }
                this.file_Loading = false
            })
            .catch(err => {
                this.file_Loading = false
            });
        },

        // 点击模块
        ClickModule(data){
            this.moduleId = data.moduleId
            this.submodulelist = data.pointList
            this.submoduleindex = 0
            if(this.submodulelist.length !=0){
                this.pointList = this.submodulelist[0].pointList
            }
        },

        // 选中放大子模块
        SelectedBig(data,index){
            this.submoduleindex = index
            this.pointList = data
        },

        setName(datas,num) {
          // 递归遍历树形结构 [{[{},{},{}]},{},{}]
          let menuList = [];
          for (var i in datas) {
            let map = {};
            map.text = datas[i];
            if(num){
                map.max = num;
            }else{
                map.max = 100;
            }
            menuList.push(map);
          }
          return menuList;
        },

        // 秒转时分秒
        formatSeconds(value) {
            let result = parseInt(value)
            let h = Math.floor(result / 3600) < 10 ?   Math.floor(result / 3600) : Math.floor(result / 3600);
            let m = Math.floor((result / 60 % 60)) < 10 ?   Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
            let s = Math.floor((result % 60)) < 10 ?  Math.floor((result % 60)) : Math.floor((result % 60));
            let res = '';
            if(h !== '00') res += `${h}时`;
            if(m !== '00') res += `${m}分`;
            res += `${s}秒`;
            return res;
        },

        // 时间戳转日期
        fmtDate(obj){
            var date =  new Date(obj);
            var y = 1900+date.getYear();
            var m = "0"+(date.getMonth()+1);
            var d = "0"+date.getDate();
            return y+"-"+m.substring(m.length-2,m.length)+"-"+d.substring(d.length-2,d.length);
        },

        // 做题量统计图
        myquestionsNum(data,num){
            var chartDom = document.getElementById('questionsNum');
            var myChart = echarts.init(chartDom);
            var option;
            // 绘制图表
            option = {
                tooltip: {
                    trigger: 'axis'
                },
                radar: [
                    {
                        // indicator: [
                        //     {text: '数量关系', max: 100},
                        //     {text: '公共基础知识', max: 100},
                        //     {text: '资料分析', max: 100},
                        //     {text: '推理判断', max: 100},
                        //     {text: '言语理解与表达', max: 100}
                        // ],
                        indicator: data,
                        radius: 80,
                        center: ['50%', '40%'],
                    }
                ],
                series: [
                    {
                        type: 'radar',
                        tooltip: {
                            trigger: 'item'
                        },
                        color: ["#FB7878"],
                        areaStyle: {},
                        data: [
                            {
                                value: num,
                                name: '做题量'
                            }
                        ]
                    },
                ]
            }
            option && myChart.setOption(option);
        },

        // 正确率统计图
        myaccuracy(data,num){
            var chartDom = document.getElementById('accuracy');
            var myChart = echarts.init(chartDom);
            var option;
            // 绘制图表
            option = {
                tooltip: {
                    trigger: 'axis'
                },
                radar: [
                    {
                        indicator: data,
                        radius: 80,
                        center: ['50%', '40%'],
                    }
                ],
                series: [
                    {
                        type: 'radar',
                        tooltip: {
                            trigger: 'item'
                        },
                        color: ["#00D8A1"],
                        areaStyle: {},
                        data: [
                            {
                                value: num,
                                name: '正确率 %'
                            }
                        ]
                    },
                ]
            }
            option && myChart.setOption(option);
        },

        // 耗时统计图
        mytime_consuming(result){
            var chartDom = document.getElementById('time_consuming');
            var myChart = echarts.init(chartDom);
            var option;
            var that = this
            // 绘制图表
            option = {
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                       return that.formatSeconds(params.value)
                    }
                },
                legend: {
                    // orient: 'vertical',
                    bottom: 'bottom',
                },
                series: [
                    {
                        name: '每题耗时',
                        type: 'pie',
                        radius: '50%',
                        center: ['50%', '36%'],
                        data: result,
                        label: {
                            show: false
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            },
                            label: {
                                show: true
                            }
                        }
                    }
                ]
            }
            option && myChart.setOption(option);
        },

        // 获取个人信息数据
        GetUserInfo(){
            this.mock_Loading = true
            GetUserInfo(this.subjecparams.subjectId).then((res) => {
                if(res.ret == 1){
                    this.mockList = res.data.mockList
                }else{
                    console.log(res.msg)
                }
                this.mock_Loading = false
            })
            .catch(err => {
                this.mock_Loading = false
            });
        },

        // 查看答卷
        Checkpaper(data){
            this.$router.push({path: '/QuestionPapers',query: {userPaperId: data.userPaperId}})
        },

        // 点击我要吐槽
        ClickDiss(){
            this.$refs.Click_Diss.ClickDissmodal()
        },

        // 点击联系客服
        ClickKf(){
            this.$refs.Click_kf.Click_kf()
        }
    },
}
</script> 

<style lang='less' scoped>
    .MyFiles{
        display: flex;
        flex-flow: column;
        min-height: 100vh;
        background-color: #f8f8f8;
        .middle{
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            min-width: 1200px;
            .content{
                width: 1200px;
                text-align: left;
                .Choose_subject{
                    margin-top: 70px;
                    span{
                        font-size: 16px;
                        font-weight: bold;
                        color: #333333;
                        margin-right: 12px;
                    }
                }
                .Brush_data_report{
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 12px;
                    padding: 10px 20px;
                    margin-top: 20px;
                    position: relative;
                    .report_top{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        .report_tit{
                            font-size: 20px;
                            font-weight: bold;
                            color: #333333;
                        }
                    }
                    .report_content{
                        display: flex;
                        justify-content: space-between;
                        .report_left{
                            .moduleName_list{
                                margin-top: 28px;
                                .list_item{
                                    width: 160px;
                                    height: 40px;
                                    line-height: 40px;
                                    background: #F8F9FA;
                                    border-radius: 4px;
                                    padding: 0 10px;
                                    font-size: 16px;
                                    font-weight: 400;
                                    color: #333333;
                                    text-align: center;
                                    margin-bottom: 20px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                    cursor: pointer;
                                }
                                .active{
                                    background: #FB7878;
                                    color: #FFFFFF;
                                }
                            }
                        }
                        .report_right{
                            width: 991px;
                            margin-top: 16px;
                            background: #F8F9FA;
                            border-radius: 8px;
                            padding: 10px 20px;
                            .allList{
                                .Tablelist_item{
                                    background: #FFFFFF;
                                    border-radius: 4px;
                                    padding: 5px;
                                    .operation{
                                        color: #E30000;
                                        text-decoration: underline;
                                    }
                                }
                                .Tablelist_item:last-child{
                                    margin-top: 10px;
                                }
                            }
                            .submodule_Ellipsis{
                                position: relative;
                                top: 50%;
                                transform: translateY(-50%);
                                text-align: center;
                                font-size: 16px;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                    }
                    .report_Ellipsis{
                        text-align: center;
                        font-size: 16px;
                        font-weight: 400;
                        color: #999999;
                        margin: 58px 0 96px;
                    }
                }
                .statistics{
                    width: 100%;
                    background: #FFFFFF;
                    border-radius: 12px;
                    padding: 20px;
                    margin-top: 20px;
                    position: relative;
                    .statistics_tit{
                        font-size: 14px;
                        font-weight: 400;
                        color: #666666;
                        margin-bottom: 13px;
                        .title{
                            font-size: 20px;
                            font-weight: bold;
                            color: #333333;
                            margin-right: 10px;
                        }
                    }
                    .allstatistics{
                        display: flex;
                        justify-content: space-between;
                        .statistics_item{
                            width: 373px;
                            height: 397px;
                            background: #FFFFFF;
                            border: 1px solid #DEDEDE;
                            border-radius: 8px;
                            /deep/.ivu-card-body{
                                padding: 0;
                            }
                        }
                    }
                    .statistics_Ellipsis{
                        margin: 60px 0 132px;
                        text-align: center;
                        img{
                            width: 400px;
                            height: 210px;
                        }
                        p{
                            margin-top: 10px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }
                .mock_analyse{
                    background: #FFFFFF;
                    border-radius: 12px;
                    padding: 20px;
                    margin: 20px 0 30px;
                    position: relative;
                    .mock_analyse_tit{
                        font-size: 20px;
                        font-weight: bold;
                        color: #333333;
                    }
                    .mock_analyse_content{
                        background: #F8F9FA;
                        border-radius: 8px;
                        margin-top: 10px;
                        .allmockList{
                            // display: flex;
                            padding: 20px;
                            white-space: nowrap;
                            overflow: hidden;
                            overflow-x: auto;
                            .mockList_item{
                                // flex: 1 0 auto;
                                display: inline-block;
                                vertical-align: top;
                                width: 358px;
                                background: #FFFFFF;
                                border-radius: 8px;
                                padding: 18px 20px;
                                margin-right: 24px;
                                font-weight: 400;
                                position: relative;
                                .item_line{
                                    position: absolute;
                                    top: 20px;
                                    left: 0;
                                    width: 4px;
                                    height: 19px;
                                    background: #FB7979;
                                    border-radius: 0px 2px 2px 0px;
                                }
                                .item_name{
                                    height: 50px;
                                    font-size: 16px;
                                    color: #333333;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 2;
                                    -webkit-box-orient: vertical;
                                }
                                .finish_time{
                                    font-size: 12px;
                                    color: #999999;
                                }
                                .item_Table{
                                    margin-top: 18px;
                                    /deep/.ivu-table-cell{
                                        padding: 0 8px;
                                    }
                                }
                                .item_btn{
                                    text-align: center;
                                    margin-top: 20px;
                                }
                            }
                        }
                        .allmockList::-webkit-scrollbar {
                          width: 5px;
                          height: 15px;
                        }
                        .allmockList::-webkit-scrollbar-thumb {
                          border-radius: 10px;
                          background-color: #DADADA;
                        }
                        .allmockList::-webkit-scrollbar-track {
                        //   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                          background: #ededed;
                          border-radius: 10px;
                        }
                        .analyse_Ellipsis{
                            text-align: center;
                            padding: 78px 0 74px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #999999;
                        }
                    }
                }
            }
        }
    }
    .wrap{
        flex: 1;
    }
    // 右下角按钮
    .fixed_btn{
        position: fixed;
        right: 40px;
        bottom: 92px;
        img{
            width: 80px;
            height: 80px;
        }
    }
    // 加载中
    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
        border: 1px solid #eee;
    }
</style>
